.container {
  text-align: center;
}

.images {
  margin: 150px 0 50px;
}

.images img {
  height: 300px;
}

.container h1,
.container h2 {
  line-height: 1;
  color: #eeaf4b;
}

.container h1 {
  margin: 0 0 10px;
  font-size: 80px;
}

.container h2 {
  margin: 0 0 30px;
  font-size: 50px;
}

.back {
  display: inline-block;
  margin: 10px 0;
  padding: 15px 20px;
  box-shadow: 0 0 10px rgba(19, 56, 101, 0.5);
  transition: background 0.5s ease;
}

.back:hover {
  background-color: #3a669c;
}

@media (max-width: 991px) {
  .images {
    margin: 50px 0;
  }

  .images img {
    height: 200px;
  }

  .container h1 {
    font-size: 60px;
  }

  .container h2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .images img {
    height: 120px;
  }

  .container h1 {
    font-size: 40px;
  }

  .container h2 {
    font-size: 26px;
  }
}

@media (max-width: 380px) {
  .images {
    margin: 50px 0 30px;
  }

  .images img {
    height: 90px;
  }
}
