.galleryItem {
  position: relative;
}

.galleryItem:not(:last-child) {
  margin-bottom: 20px;
}

.image {
  display: block;
  width: 100%;
  transition: 0.5s ease;
}

.author {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: none;
}

.authorImage {
  margin-right: 8px;
  border-radius: 100%;
  max-width: 40px;
  max-height: 40px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 10px;
  text-decoration: none;
  color: #fff;
}

.authorInfo:hover {
  color: #fff;
}

.authorName {
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.authorName:hover {
  opacity: 1;
}

.date {
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.date:hover {
  opacity: 1;
}

.like {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: none;
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.like:hover {
  opacity: 1;
}

.like.active {
  opacity: 1;
}

.galleryItem:hover .image,
.galleryItem:active .image,
.galleryItem:focus .image {
  filter: brightness(60%);
}

.galleryItem:hover .author,
.galleryItem:active .author,
.galleryItem:focus .author {
  display: flex;
}

.galleryItem:hover .like,
.galleryItem:active .like,
.galleryItem:focus .like {
  display: flex;
}
