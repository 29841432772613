.footer {
  padding: 20px 0;
  background: #133865;
}

.container {
  display: flex;
  justify-content: space-between;
}

.copyright,
.contacts {
  color: #fff;
}

@media (max-width: 575px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .copyright {
    margin-bottom: 10px;
  }
}
