.collection {
  margin-bottom: 50px;
}

.collectionImages {
  display: grid;
  grid-auto-flow: dense;
  grid-auto-rows: 50%;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 15px;
  height: 300px;
  gap: 2px;
}

.imageWrapper {
  background: #f5f5f5;
}

.imageWrapper:first-child {
  grid-column: span 2;
  grid-row: span 2;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collectionTitle {
  margin: 0 0 5px;
  font-size: 18px;
}

.collectionInfo {
  margin-bottom: 10px;
  font-size: 14px;
}

.collectionPhotos::after {
  content: "\00B7";
  display: inline-block;
  margin: 0 5px;
}

.tags {
  margin: 0;
  padding: 0;
}

.tagItem {
  display: inline-block;
  margin: 3px;
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 0 0 3px rgba(19, 56, 101, 0.3);
  font-size: 14px;
  line-height: 1;
  color: #fff;
  background: #3e5775;
}

@media (max-width: 767px) {
  .collection {
    margin-bottom: 30px;
  }
}
