.photo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.photoContainer {
  flex: 1 1 auto;
  margin-right: 50px;
}

.image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 600px;
}

.photoInfo {
  flex: 0 0 250px;
}

.author {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(134, 167, 205, 0.3);
  padding-bottom: 15px;
  text-decoration: none;
}

.authorImage {
  margin-right: 8px;
  border-radius: 100%;
  max-width: 42px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.authorName {
  font-size: 18px;
  color: #3a669c;
}

.authorName:hover {
  color: #eeaf4b;
}

.totalPhotos {
  font-size: 14px;
  color: #767676;
}

.details {
  margin: 15px 0;
  border-top: 1px solid rgba(134, 167, 205, 0.3);
  padding-top: 15px;
}

.details p {
  margin: 0 0 5px;
  font-size: 15px;
  line-height: 1.3;
}

.details b {
  margin-right: 5px;
}

.tags {
  margin: 0;
  padding: 0;
}

.tagItem {
  display: inline-block;
  margin: 3px;
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 0 0 3px rgba(19, 56, 101, 0.3);
  font-size: 14px;
  line-height: 1;
  color: #fff;
  background: #3e5775;
}

.relatedTitle {
  font-weight: 400;
}

.back {
  position: absolute;
  left: 50px;
  top: 124px;
  border: none;
  border-radius: 100%;
  padding: 20px;
  width: 25px;
  height: 25px;
  box-shadow: 0 0 10px rgba(19, 56, 101, 0.5);
  color: transparent;
  background: url("../../assets/image/backArrow.svg") no-repeat center center;
  background-size: 25px;
  transition: background 0.5s ease;
}

.back:hover {
  background-color: #3a669c;
  cursor: pointer;
}

.relatedCollections {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1490px) {
  .back {
    position: inherit;
    display: block;
    border: 2px solid #eeaf4b;
    border-radius: 0;
    padding: 10px 15px 10px 40px;
    width: auto;
    height: auto;
    box-shadow: none;
    font-size: 18px;
    color: #eeaf4b;
    background: #fff url("../../assets/image/backArrow.svg") no-repeat 10px center;
    background-size: 20px;
    transition: background, border, box-shadow 0.5s ease;
  }

  .back:hover {
    border-color: #3a669c;
    box-shadow: 0 0 10px rgba(19, 56, 101, 0.5);
    color: #3a669c;
    background-color: transparent;
  }
}

@media (max-width: 991px) {
  .photoContainer {
    margin-right: 20px;
  }

  .relatedCollections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .photo {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .photoContainer {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .photoInfo {
    flex-basis: 100%;
  }
}

@media (max-width: 575px) {
  .relatedCollections {
    grid-template-columns: repeat(1, 1fr);
  }
}
