.container {
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 1260px) {
  .container {
    padding: 0 20px;
  }
}
