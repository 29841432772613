.gallery {
  display: flex;
  margin-bottom: 50px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
}

.row:not(:last-child) {
  margin-right: 20px;
}

.more {
  display: block;
  margin: 0 auto;
  border: 2px solid #eeaf4b;
  padding: 10px 15px;
  font-size: 18px;
  color: #eeaf4b;
  background: #fff;
  transition: background, border, box-shadow 0.5s ease;
}

.more:hover {
  border-color: #3a669c;
  box-shadow: 0 0 10px rgba(19, 56, 101, 0.5);
  color: #3a669c;
  cursor: pointer;
}
