@font-face {
  src:
    local(""),
    url("assets/fonts/MullerRegular.woff2") format("woff2"),
    url("assets/fonts/MullerRegular.woff") format("woff");
  font-family: "Muller";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src:
    local(""),
    url("assets/fonts/MullerMedium.woff2") format("woff2"),
    url("assets/fonts/MullerMedium.woff") format("woff");
  font-family: "Muller";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  src:
    local(""),
    url("assets/fonts/MullerBold.woff2") format("woff2"),
    url("assets/fonts/MullerBold.woff") format("woff");
  font-family: "Muller";
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #3a669c;
}

a:hover {
  color: #eeaf4b;
}

button {
  font-family: "Muller", sans-serif;
}

main {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  main {
    margin-bottom: 30px;
  }
}
